html {
  height: 100%;
}

body {
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  min-height: 100%;
  font-size: 16px;
  font-family: $font-standard;
  color: $font-color;
  font-weight: 300;
}

h1, h2, h3, h4, h5 {
  font-family: $font-title;
  color: $brown;
}

h2 {
  font-size: 36px;
  font-weight: bold;
}

p, ul, li, ol {
  color: $font-color;
}

p {
  line-height: 27px;
  margin: 0 0 22px 0;
}

a {
  outline: 0;
  color: $orange;
  &:hover,
  &:focus {
    outline: 0;
    color: $brown;
  }
}

section {
  &.grey {
    background: $grey;
    width: 100%;
    float: left;
  }
  &.pattern {
    background: url(/images/bg-floor.jpg) repeat;
    background-attachment: fixed;
    width: 100%;
    float: left;
    h2 {
      color: $orange;
    }
    a {
      &:hover,
      &:focus {
        color:white;
      }
    }
  }
  &.default {
    h1, h2 {
      font-size: 24px;
      margin-top: 30px;
      margin-bottom: 5px;
      text-transform: inherit;
    }
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 18px;
    }
    h1, h2, h3, h4, h5 {
      font-weight: bold;
    }
    ul, ol {
      line-height: 27px;
      margin: 0 0 15px 0;
      padding: 0 0 0 16px;
    }
  }
  &.padded {
    padding: 65px 0;
    h1 {
      margin-top:0;
    }
  }
}

.btn-back {
  display: block;
  background: $brown;
  color: white;
  padding: 4px 12px 6px 28px;
  position: relative;
  &:before {
    content: "\f060";
    font-family: FontAwesome;
    position: absolute;
    left: 10px;
    top: 6px;
    color: white;
    font-size: 13px;
  }
  &:hover,
  &:focus {
    background: $orange;
    color: white;
    text-decoration: none;
  }
}

.list-contactopties {
  margin-top: 3px;
  float: left;
  width: 100%;
  .list-group-item {
    border: 0;
    padding: 10px 15px 10px 0;
    font-size: 16px;
    font-family: $font-standard;
    i {
      width: 30px;
      height: 30px;
      padding-top: 7px;
      background: $brown;
      color: white;
      margin-right: 8px;
      text-align: center;
    }
    &:hover,
    &:focus {
      background: transparent;
      color: $orange;
    }
  }
}

.contact {
  .col-md-4 {
    padding-left:30px;
  }
  h2 {
    float: left;
    width: 100%;
  }
}

.contactformulier {
  background: $grey;
  padding: 30px 0;
  h2 {
    font-size: 24px;
    margin-top: 0;
  }
}

#kaart {
  position: relative;
  z-index: 0;
  height: calc(100vh - 266px);
  padding-right: 0 !important;
  #map-canvas {
    width: 100%;
    height: calc(100vh - 266px);
    min-height: 400px;
    position: relative;
    z-index: 0;
    .googleInfo {
      padding:0;
      a {
        font-size:20px;
        margin:0 0 9px 0;
        float:left;
        width:100%;
        display:block;
      }
      p {
        margin:0;
        padding:0;
        span {
          background:white;
          border-top:1px solid $grey;
          border-bottom:1px solid $grey;
          padding:3px;
          width:100%;
          display:block;
          float:left;
          i {
            margin-right:7px;
            &.icon-map-marker-multiple {
              font-size:17px;
            }
          }
        }
        span + span {
          background:white;
          border:0;
        }
      }
    }
  }
  #sidebar-filter {
    position: absolute;
    z-index: 1000 !important;
    top: 10% !important;
    left: 5%;
    width: 330px;
    margin: 0;
    background: $brown;
    label.title {
      color: white !important;
    }
    h3 {
      color: $orange;
    }
  }
}

#zoekers-section {
  h1 {
    color: $orange;
    font-size: 36px;
  }
  p {
    color: white;
  }
  .bootstrap-table {
    table.table-row-links {
      margin-bottom: 45px;
      background: white;
      thead {
        tr {
          th {
            border-radius: 0 !important;
            background: $orange;
            border-right: 1px solid #fff;
            &:last-child {
              border-right: 0;
            }
          }
        }
      }
    }
  }
}

#zoekers-table {
  margin-top: 30px;
}

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 0 0 !important;
  -moz-box-shadow: 0 0 0 !important;
  box-shadow: 0 0 0 !important;
}

.fancybox-skin {
  border-radius: 0 !important;
}