@media only screen and (min-width : 1200px) {
  #sidebar-filter.affix-top {
    width: 330px !important;
  }
  #sidebar-filter.affix-bottom {
    width: 330px !important;
  }

  #sidebar-filter.affix {
    width: 330px !important;
  }
}
@media only screen and (min-width : 992px) {
  #sidebar-filter.affix-top {
    position: static;
    width: 262px;
  }
  #sidebar-filter.affix-bottom {
    position: absolute;
    width: 262px;
  }

  #sidebar-filter.affix {
    position: fixed;
    top: 116px;
    width: 262px;
  }
}
