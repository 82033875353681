@media only screen and (max-width : 767px) {
  body {
    padding-top:95px !important;
  }
  header {
    height: 95px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    .header-bar {
      .navbar-brand {
        margin-top:0;
        max-width:75px;
        position:absolute;
        padding:0;
        width:60px;
        height:60px;
        z-index:2001;
        span {
          font-size:20px;
          line-height:20px;
          top:19px;
          left:70px;
        }
      }
    }
    .top-nav {
      ul.social-small {
        display:none;
      }
      .navbar-list {
        margin:0 0 0 -15px;
        li {
          float:left;
        }
      }
    }
  }

  .navbar-default {
    position: fixed;
    z-index: 2000;
    top: 38px;
    right: 0;
    background: transparent;
    border: 0;
    margin-bottom:0;
    width:100%;

    #navbar {
      margin-top:5px;
      background:white;
      .navbar-nav {
        margin:0 -15px;
        li {
          border-bottom:1px solid $brown;
          a {
            font-size:18px;
            color:$brown;
            padding-top:15px;
            padding-bottom:15px;
            span + span {
              text-transform: lowercase;
            }
          }
          &:last-child {
            border-bottom:0;
            a {
              background:white;
              color:$orange;
              span {
                &:last-child {
                  display:none;
                }
              }
            }
          }
          &.active {
            a {
              background:$orange;
              color:white;
            }
          }
        }
      }
    }

    .navbar-toggle {
      background:$orange;
      border-radius: 0;
      border:0;
      margin-top:10px;
      .icon-bar {
        background:white;
      }
      &:focus,
      &:hover {
        background:$orange;
      }
    }

  }

  section {
    &.padded {
      padding:30px 0;
    }
  }

  .img-container {
    height:auto;
    .btn-back {
      top:30px;
      padding-top:4px;
      &:before {
        top:7px;
      }
    }
    .img-large {
      height:auto;
      float:left;
      width:100%;
      .slider-detail {
        width:100%;
        height:230px;
        min-height:230px;
        position:relative;
        float:left;
        .owl-item {
          height:230px;
          .image {
            margin-top:15px;
            height:230px !important;
            float:left;
            position:relative;
            background-size:cover !important;
          }
        }
      }
      .image {
        height:230px;
      }
      .card {
        left:0;
        width:100%;
        float:left;
        position:relative;
      }
      &:after {
        top:30px;
        z-index:100;
      }
    }
    a.img-large {
      &:after {
        z-index:100;
      }
    }
  }
  .desc {
    margin-top:0;
    ul.nav-tabs {
      li {
        a {
          padding:8px;
        }
      }
    }
  }
  #koopwoningen {
      h2 {
        text-align: left;
        padding:0 0 45px 0 !important;
      }
  }
  .row-pagination {
    float:left;
    width:100%;
  }
  .s-houses {
    h2 {
      text-align:center;
      padding:0 0 60px 0 !important;
    }
    .container {
      #form-order {
        top:-84px;
        left:0;
        right:0;
        margin:0 auto;
        width:90%;
      }
    }

    .houses {
      margin:40px 0 30px 0;
      height:auto;
      float:left;
      width:100%;
      .house {
        .image {
          max-height:auto;
          .price {
            font-weight:400;
          }
        }
      }
      .owl-stage-outer {
        height:510px;
      }
      .owl-item {
        margin-top:15px;
        height:490px;
      }


        .owl-nav {
          width:100%;
          top:-40px;

          .owl-prev,
          .owl-next {
            width:49%;
            margin:0 1px 0 0;
            background:rgba(255,255,255,0.1);
            border:1px solid white;
          }
          .owl-next {
            float:right;
            margin:0;
          }
        }

    }
  }


  #zoeker {
    #mijn-zoeker {
      &:before {
        content:"";
        background:none;
        width:0;
        height:0;
      }
    }
    ul.options {
      li {
        &.plaats {
          span {
            float:left;
            margin-bottom:5px;
            &:first-child {
              width:100%;
            }
          }
        }
      }
    }
  }

  .banner {
    .btn-banner {
      padding:15px;
    }
  }
  #kaart {
    height:100%;
    #sidebar-filter {
      display:none
    }
    #map-canvas {
      min-height:100px;
      height:100%;
    }
  }

  .contact {
    h2 {
      margin-top:0 !important;
    }
  }
  #inschrijven {
    width:100%;
  }

  .col-houses {
    position:relative;
    #form-order {
      top: -230px;
      right: 15px;
      margin: 0;
      .btn {
        text-indent: 0px;
        max-width: 25px;
        float: right;
        .bs-caret {
          &:before {
            content:"\f1de";
            font-family: FontAwesome;
            position:absolute;
            left:0;
            right:0;
            top:5px;
            display:block;
            font-size:20px;
          }
        }
        .caret {
          border:0;
        }
      }
    }
  }

  #compare {
    ul.compare-houses {
      li {
        width:100%;
        a {
          span.address {
            h4 {
              font-size:18px;
            }
          }
        }
      }
    }
  }

  .col-sidebar {
    padding-right:15px;
    float: left;
    width: 100%;
    margin: 0 0 30px 0;
    .sidebar {
      float:left;
      display: block !important;
      position: relative;
      margin: 0;
      width: 100%;
      padding-bottom: 15px;
    }

    .sidebar {
      .filter-option {
        display:none;
      }
      .filter-option.s-submit,
      .filter-option.s-search {
        display:block !important;
        min-height:20px;
      }
      .filter-option.s-submit {
        margin-top:10px;
        .btn-primary {
          width:100%;
        }
      }
    }
  }

  .col-zoeken,
  .col-gegevens,
  .col-send {
    float:left;
    width:100%;
  }

  .footer-columns {
    .col {
      margin-top:15px;
    }
  }

  footer {
    padding:15px 0 15px 0;
    .copyright {
      padding-top:0px;
      p {
        margin:0;
      }
    }
    ul {
      &.social {
        li {
          a {
            width:30px;
            height:30px;
            font-size:14px;
          }
        }
      }
    }
  }
}