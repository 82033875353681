.banner {
  width:100%;
  float:left;
  position:relative;
  text-align:center;

  .btn-banner {
    font-family: $font-title;
    color:$brown;
    font-size:20px;
    width:100%;
    padding:30px;
    text-align:center;
    display:block;
    border:1px solid $brown;
    transition: 0.15s;
    span {
      color:$orange;
    }
    &:hover {
      text-decoration: none;
      background:$brown;
      color:white;
    }
  }
}

.banner-contact {
  text-align:center;
  padding:30px 0;
  h3, h4 {
    margin:0;
    padding:0;
  }
  h3 {
    color:white;
    margin:0 0 10px 0;
    font-family:$font-title;
  }
  h4 {
    color:$orange;
    font-size:24px;
    font-family:$font-title;
  }
  a {
    &:hover,
    &:focus {
      color:white;
    }
  }
}