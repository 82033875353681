.alert-top {
  text-align: center;
  border-left: none;
  border-right: none;
  border-radius: 0;
  margin-bottom:0;
}
.row-houses {
    .alert-info {
      margin:10px 8px;
    }
}