#new_profiles {
  padding:45px 0;
  h2 {
    padding:0;
    margin:0;
    font-size:24px;
  }
  .view-all {
    margin-top:40px;
    a {
      color:$brown;
      &:before,
      &:after {
        background:$brown;
      }
      &:hover {
        color:$orange;
        &:before,
        &:after {
          background:$orange;
        }
      }
    }
  }
  #profiles-carousel {
    height:352px;
    min-height:352px;
    position:relative;
    margin-top:30px;
    z-index:1;
    .owl-item {
      width:100%;
      height:352px;
      padding:5px;
    }
    .card-profile {
      background:white;
      float:left;
      width:100%;
      text-align:center;
      position:relative;
      border:1px solid white;

      span {
        display:block;
        width:100%;
        position:absolute;
        bottom:0px;
        left:0;
        right:0;
        margin:0 auto;
        padding:6px 8px 8px 8px;
        background:$orange;
        color:white;
      }

      .image-container {
        float:left;
        width:100%;
        background:$brown;
        padding:15px 0;
        .image {
          margin:0 auto;
          border:5px solid white;
          border-radius:50%;
          width:150px;
          height:150px;
          overflow:hidden;
          position:relative;
          background:white;

          img {
            max-width:90%;
            border-radius: 50%;
            position:absolute;
            bottom:0;
            left:0;
            right:0;
            margin:0 auto;
            -webkit-transition: all 300ms linear;
            -moz-transition: all 300ms linear;
            -o-transition: all 300ms linear;
            -ms-transition: all 300ms linear;
            transition: all 300ms linear;
          }
        }
      }

      .owl--text {
        color:$font-color;
        padding:15px;
        float:left;
        width:100%;
        position: relative;
        overflow:hidden;
        height:160px;
        display:block;
        .text-inner {
          font-size:16px;
          font-weight:300;
          position: relative;
          z-index:10;
        }
        &:before {
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          text-decoration: inherit;
          content: "\f10e";
          position: absolute;
          color: #f2f5f7;
          bottom: 20px;
          font-size: 30px;
          right: 20%;
          opacity: 0.46;
          -webkit-transform: scale(4.2) rotate(45deg);
          -moz-transform: scale(4.2) rotate(45deg);
          transform: scale(4.2) rotate(45deg);
          -webkit-transition: all 300ms linear;
          -moz-transition: all 300ms linear;
          -o-transition: all 300ms linear;
          -ms-transition: all 300ms linear;
          transition: all 300ms linear;
          z-index:0;
        }
      }


      &:hover,
      &:focus {
        .image-container {
          .image {
            img {
              -webkit-transform: scale(1.1) rotate(10deg);
              -moz-transform: scale(1.1) rotate(10deg);
              transform: scale(1.1) rotate(10deg);
            }
          }
        }
        .owl--text {
          &:before {
            opacity:0;
            -webkit-transform: scale(7.2) rotate(15deg);
            -moz-transform: scale(7.2) rotate(15deg);
            transform: scale(7.2) rotate(15deg);
          }
        }
      }
    }
    .owl-controls {
      position:absolute;
      top:-60px;
      right:0;
      .owl-nav {
        width:84px;
        height:36px;
        position:relative;
        .owl-prev,
        .owl-next {
          float:left;
          margin:0 5px 0 0;
          width:36px;
          text-align:center;
          height:36px;
          display:block;
          border:1px solid transparent;
          position:relative;
          &:after {
            position:absolute;
            font-family: FontAwesome;
            color:$orange;
            font-size:20px;
            width:36px;
            height:22px;
            line-height:22px;
            top:5px;
            left:0;
            right:0;
            margin:0 auto;
          }
          &:hover,
          &:focus {
            border:1px solid $brown;
            &:after {
              color:$brown;
            }
          }
        }
        .owl-prev {
          &:after {
            content:"\f060";
          }
        }
        .owl-next {
          &:after {
            content:"\f061";
          }
        }
      }
    }
  }
}

#zoeker {
  ul {
    &.options {
      margin:25px 0 10px 0;
      padding:0;
      float:left;
      width:100%;
      position:relative;
      list-style: none;
      li {
        width:100%;
        display:block;
        float:left;
        color: $font-color;
        border-bottom:1px solid #eee;
        font-size:$font-16;
        margin:0 0 10px 0;
        padding:0 0 10px 40px;
        position: relative;
        span {
          float:left;
          &.badge {
            float:right;
            color:$font-color;
            background:$grey;
            font-size:$font-16;
            font-weight:400;
          }
        }
        &.prijs {
          .badge {
            margin-left:5px;
            background:transparent;
          }
          &:after {
            content:"\f012";
            font-size:18px;
            top:3px;
            left:2px;
          }
        }
        &.plaats {
          .badge {
            margin-left:5px;
          }
          &:after {
            content:"\f011";
            font-size:20px;
            top:2px;
            left:2px;
          }
        }
        &.slaapkamers {
          &:after {
            content:"\f00f";
            top:5px;
            left:0px;
          }
        }
        &.badkamers {
          &:after {
            content:"\f00e";
            font-size:17px;
            top:0px;
            left:2px;
          }
        }
        &.kamers {
          &:after {
            content:"\f010";
            font-size:21px;
            top:2px;
            left:5px;
          }
        }
        &.oppervlakte {
          span {
            &.badge {
              background:transparent;
            }
          }
          &:after {
            content:"\f00d";
            border:1px solid $font-color;
            width:20px;
            height:20px;
            text-align:center;
            padding-top:2px;
            top:2px;
            left:2px;
          }
        }
        &.aangemeld {
          border-bottom:0;
          span {
            &.badge {
              background:transparent;
            }
          }
          &:after {
            content:"\f006";
            width:20px;
            height:20px;
            text-align:center;
            font-size:20px;
            padding-top:2px;
            top:2px;
            left:2px;
          }
        }
        &:after {
          font:normal normal normal 14px/1 'BrixIziKotw';
          position:absolute;
          width:30px;
          height:30px;
        }
      }
    }
  }

  #mijn-zoeker {
    background:$grey;
    padding:30px;
    margin-top:30px;
    margin-bottom:30px;
    position: relative;
    float:left;
    width:100%;

    #form-contact {
      .alert {
        float:left;
        width:100%;
      }
    }
    .form_intro_zoeker {
      position:relative;
      width:100%;
      float:left;
    }

    h2 {
      margin-top:0;
      float:left;
      width:100%;
      display:block;
    }
    p {
      float:left;
      width:100%;
    }
    #form-zoeker {
      float:left;
      width:100%;
    }
    &:before {
      content:"";
      background: url(/images/arrow-left.png);
      position: absolute;
      left:-75px;
      top:-5px;
      width:90px;
      height:53px;
      -ms-transform: rotate(-13deg); /* IE 9 */
      -webkit-transform: rotate(-13deg); /* Chrome, Safari, Opera */
      transform: rotate(-13deg);
    }
  }
  .bootstrap-table {
    margin-top:30px;
    float:left;
    width:100%;
  }
}

