header {
  height: 266px;
  position: relative;

  .header-bar {
    background: $brown;
    width: 100%;
    height: 80px;
    position: relative;

    .navbar-brand {
      margin-top: -35px;
      padding: 0 15px 15px 0;
      z-index: 2000;
      img {
        max-width: 100%;
      }
      span {
        position: absolute;
        color: white;
        font-size: 36px;
        line-height: 36px;
        font-weight: 100;
        font-family: $font-title;
        left: 183px;
        top: 20px;
      }
    }

    .slogan {
      float: right;
      color: white;
      font-family: $font-standard;
      font-style: italic;
      font-weight: 300;
      font-size: 20px;
      line-height: 20px;
      padding-top: 27px;
    }
    .whatsapp-ons {
      position:absolute;
      right:-60px;
      bottom:-117px;
      transform:rotate(15deg);
      color:$brown;
      font-size:28px;
      font-family:'Dancing Script', cursive;
      z-index:2001;
      &:before {
        content:"";
        position:absolute;
        top:-115px;
        right:0;
        left:0;
        margin:0 auto;
        width:44px;
        height:121px;
        background:url('/images/arrow-whatsapp.png') no-repeat;
        background-size:100%;
        transform:rotate(-10deg);
      }
    }
  }
  .top-nav {
    background: $grey;
    .nav-menu {
      float: right;
    }
    .navbar-list {
      float: left;
      li {
        border-right: 1px solid $brown;
        margin: 9px 7px 9px 0;
        padding: 0 9px 0 0;

        a {
          padding: 0 0 2px 0;
          color: $brown;
          line-height: 14px;
          text-transform: lowercase;
          &:hover {
            background: transparent;
            color: $orange;
          }
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
    .flags {
      margin: 9px 0 0 0;
      padding: 0;
      list-style: none;
      float: left;
      position: relative;
      li {
        width: 16px;
        height: 16px;
        margin-right: 3px;
        float: left;
        .dutch {
          background: url(/images/flags.png) -16px -16px;
          width: 16px;
          height: 16px;
          float: left;
          &:hover {
            background: url(/images/flags.png) -16px 0;
          }
        }
        .english {
          background: url(/images/flags.png) 0px -16px;
          width: 16px;
          height: 16px;
          float: left;
          &:hover {
            background: url(/images/flags.png) 0 0;
          }
        }
        &.active {
          .dutch {
            background: url(/images/flags.png) -16px 0;
          }
          .english {
            background: url(/images/flags.png) 0 0;
          }
        }
      }
    }

    ul.social-small {
      list-style: none;
      float: left;
      margin: 4px 0 0 8px;
      padding: 0;
      li {
        float: left;
        margin-right: 3px;
        a {
          background: #5185A6;
          border-radius: 50%;
          width: 26px;
          height: 26px;
          display: block;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          padding-top: 1px;
          -ms-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .3s;
          transition: .3s;
          i {
            color: white;
          }

          &:hover,
          &:focus {
            background: $orange;
            -ms-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            transform: rotate(360deg);
            i {
              color: white;
            }
          }
        }
        &:first-child {
          a {
            background:#3B5998;
            &:hover,
            &:focus {
              background:$orange;
            }
          }
        }
        &:last-child {
          margin-right: 0;
          a {
            background:rgba(37, 211, 102, 1);
            &:hover,
            &:focus {
              background:$orange;
            }
          }
        }
      }
    }
  }
}
