.img-container {
  width:100%;
  height:450px;
  overflow:hidden;
  position:relative;
  .btn-back {
    position:absolute;
    left:15px;
    top:15px;
    z-index:100;
  }
  .status {
    position:absolute;
    z-index:101;
    right:-43px;
    top:37px;
    color:white;
    text-align:center;
    margin:0;
    padding:6px 0;
    width:200px;
    -moz-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
    -o-transform:rotate(45deg);
    -ms-transform:rotate(45deg);
    transform:rotate(45deg);
    p {
      font-size:20px;
      margin:0;
      color:white;
    }
    &.nieuw {
      background:$orange;
    }
    &.verhuurd,
    &.verkocht,
    &.voorbehoud {
      background:$brown;
    }
    &.verwacht {
      background:#f1b007;
    }
  }
  a.img-large {
    &:after {
      content:"\f002";
      font-family: FontAwesome;
      font-size:18px;
      position:absolute;
      right:15px;
      bottom:15px;
      width:40px;
      height:40px;
      border-radius:50%;
      color:white;
      text-align:center;
      padding-top:7px;
      background:rgba(255,255,255,0.4);
      z-index:1000;
    }
    &:hover,
    &:focus {
      .image {
        opacity:0.75;
      }
    }
  }
  .img-large {
    width:100%;
    height:450px;
    display:block;
    background:white;
    position:relative;
    .slider-detail {
      width:100%;
      height:450px;
      min-height:450px;
      position:relative;
      z-index:1;
      .owl-item {
        width:100%;
        height:450px;
      }
    }
    .image {
      width:100%;
      height:450px;
      display:block;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
    .card {
      background:rgba(255,255,255,1);
      position:absolute;
      bottom:15px;
      left:15px;
      padding:15px;
      width:350px;
      z-index:10;

      .title {
        float:left;
        width:100%;
        .price {
          margin-top:15px;
          font-size:36px;
          color:$brown;
        }
      }
      h1, h2 {
        margin:0;
        padding:0;
      }
      h1 {
        font-family: $font-title;
        font-weight:bold;
        font-size:$font-24;
        margin-bottom:3px;
      }
      h2 {
        font-family: $font-standard;
        color:$orange;
        font-size:$font-16;
      }
    }
  }
}
.desc {
  margin-top:30px;
  .row-desc {
    h3 {
      margin-bottom:5px;
    }
    ul, li {
      line-height:27px;
    }
  }
  ul {
    margin:0 0 15px 0;
    padding:0 0 0 16px;
    &.nav-tabs {
      padding:0;
      margin:0;
      border-color:$brown;
      li {
        a {
          font-size:16px;
          font-weight:bold;
          border-radius:0;
          &:hover,
          &:focus {
            color:$brown;
            background:white;
            border: 1px solid $brown;
            border-bottom-color: transparent;
          }
        }
        &.active {
          a {
            color:$brown;
            border: 1px solid $brown;
            border-bottom-color: transparent;
            &:hover,
            &:focus {
              color:$brown;
              border: 1px solid $brown;
              border-bottom-color: transparent;
            }
          }
        }
      }
    }
  }
  .table-kenmerken {
    margin-top:15px;
    tbody {
      tr {
        td {
          &:first-child {
            padding-left:0;
          }
          &:last-child {
            font-weight:bold;
          }
        }
      }
    }
  }
}

ul {
  &.kenmerken {
    margin:20px 0 0 0;
    padding:0;
    float:left;
    width:100%;
    position:relative;
    list-style: none;
    li {
      width:100%;
      display:block;
      float:left;
      color: $font-color;
      font-size:$font-16;
      margin:0 0 7px 0;
      padding:0 0 0 40px;
      position: relative;
      span {
        float:left;
        &.badge {
          float:right;
          color:$font-color;
          background:$grey;
          font-size:$font-16;
          font-weight:400;
        }
      }
      &.slaapkamers {
        &:after {
          content:"\f00f";
          top:6px;
          left:0px;
        }
      }
      &.badkamers {
        margin:0;
        &:after {
          content:"\f00e";
          font-size:17px;
          top:2px;
          left:2px;
        }
      }
      &.oppervlakte {
        /*
        span {
          &.badge {
            background:transparent;
          }
        }*/
        &:after {
          content:"\f00d";
          border:1px solid $font-color;
          width:20px;
          height:20px;
          text-align:center;
          padding-top:2px;
          top:2px;
          left:2px;
        }
      }
      &:after {
        font:normal normal normal 14px/1 'BrixIziKotw';
        position:absolute;
        width:30px;
        height:30px;
      }
    }
  }
}
.row-tabs {
  margin-top:15px;
  h3 {
    font-weight:bold;
  }
}
.makelaar {
  position:relative;
  padding-bottom:30px;
  .back {
    top:140px;
    left:0;
    bottom:0;
    position:absolute;
  }
  table {
    display:none;
    tr {
      td {
        &:last-child {
          font-weight:bold;
        }
      }
    }
  }
}
.masonry-container {
  .thumbnail {
    padding:0;
    border:0;
    border-radius: 0;
  }
}

#omschrijving {
  #btnContact {
    margin-top:30px;
  }
}

#map-canvas {
  width:100%;
  height:450px;
}