$brown:           #48382a;
$brownShadow:     #2b2016;
$orange:          #f18407;
$grey:            #f2f5f7;
$beige:           #8E8575;
$purple:          #5B5868;
$purpleLight:     #9A98A2;
$purpleDark:      #23212F;

$font-color:      #8e8e8f;
$font-standard:   'Lato', sans-serif;
$font-title:      "calluna", serif;

$font-16:         16px;
$font-18:         18px;
$font-20:         20px;
$font-24:         24px;
$font-30:         30px;