@media only screen and (max-width : 1199px) {
  header {
    height:230px;
    .header-bar {
      height:70px;
      .navbar-brand {

        max-width:140px;

        img {
          max-width:100%;
        }
        span {
          font-size: 28px;
          line-height: 28px;
          left: 163px;
          top: 20px;
        }
      }
      .slogan {
        font-size: 18px;
        line-height: 18px;
        padding-top: 24px;
      }
      .whatsapp-ons {
        display:none;
      }
    }
  }

  .searchbar {
    .col-search {
      .form-control {
        font-size:20px;
      }
    }
    .tot {
      .bootstrap-select {
        .dropdown-toggle {
          span.filter-option {
            padding-left:0;
          }
        }
      }
    }
  }
  .tot,
  .vanaf {
    .bootstrap-select {
      .dropdown-toggle {
        .filter-option {
          padding-left:0;
          &:before {
            content:"" !important;
          }
        }
      }
    }
  }
  .s-houses {
    .houses {
      .item,
      .owl-item,
      .owl-stage-outer {
        height:515px;
      }
      .house {
        .owl--text {
          h3 {
            font-size:25px;
            min-height:44px;
          }
        }
        .image {
          .status,
          .price {
            font-size:18px;
          }
        }
      }
    }
  }
  .img-container {
    .img-large {
      .card {
        .title {
          .price {
            font-size:32px;
          }
        }
      }
    }
  }
  #compare {
    ul.compare-houses {
      li {
        a {
          span.address {
            h4 {
              font-size:16px;
            }
          }
        }
      }
    }
  }
}
