.form-group {
  margin-bottom: 10px;
  float: left;
  width: 100%;
  font-family: $font-standard;
  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    text-transform: initial;
  }
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    resize: none;
    width: 100%;
    height: 143px;
    padding: 6px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius:0;
  }
}

.formContainer {
  padding: 0;

  .formSubcontainer {
    border: 1px solid #ddd;
    background-color: $grey;
    padding: 10px 15px 0;
    border-radius: 0px;

    + .formSubcontainer {
      margin-top: 10px;
    }
  }
}

.has-error .dropdown-toggle {
  border-color: #a94442;
}

.has-error .select2-container--default .select2-selection--single {
  border-color: #a94442;
}

.toggle-container .toggle-target {
  display: none;
}

.radio-inline {
  min-height: 40px;
}

.select2-container--default {
  .select2-selection--multiple, .select2-selection--single {
    border: 1px solid #ccc;
    min-height: 40px;
    border-radius: 0;

    input {
      padding: 2px 12px 0;
      font-size: 14px;
      line-height: 1.42857143;
    }

    .select2-selection__choice {
      background-color: white;
      border-color: #ccc;
      margin: 3px 12px 0 -9px;
      height: 26px;
      padding: 2px 8px 0 20px;
      font-weight: 400;
      font-size: 14px;

      .select2-selection__choice__remove {
        position: absolute;
        font-size: 18px;
        vertical-align: 1px;
        color: #ddd;
        margin-left: -15px;
        margin-top: -4px;
        font-weight: 400;

        &:hover {
          color: black;
        }
      }
    }

    .select2-selection__rendered {
      padding: 2px 12px;
      display: block;
    }
  }

  .select2-selection--single {
    .select2-selection__rendered {
      padding: 4px 12px 0;
    }

    .select2-selection__arrow {
      top: 7px;
      right: 7px;

      b {
        border-color: black transparent transparent;
      }
    }
  }
}

.select2-container--default.select2-container--open .select2-selection--single {
  border-color: black;
}

.select2-results__option {
  padding: 6px 12px;
}

.select2-dropdown {
  border: solid black 1px;
  overflow: hidden;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #f5f5f5;
  color: black;
}

.form-horizontal {
  .control-label {
    color: $brown;
    padding-top: 9px;
  }
}

.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  height: 41px;
  border-radius: 0;
  font-size: 16px;
  &:focus {
    border-color: $orange;
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075), 0 0 0px rgba(241, 132, 7, .6);
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075), 0 0 0px rgba(241, 132, 7, .6);
  }
}

.input-group-addon {
  border-radius: 0;
}

.map_select {
  width: 100%;
  height: 300px;
  border: 1px solid #999;
}

.input-group-addon {
  border-radius: 0;
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  border-radius: 0;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active.active:hover,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled.disabled:hover,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active[disabled]:hover,
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled[disabled]:hover {
  background: $orange !important;
  border-radius: 0;
}

.radio + .radio {
  margin-top: 10px;
  margin-left: 20px;
}

.radio {
  padding-left: 20px;
  float: left;
}

.radio label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
  font-weight: 300;
  line-height: 16px;
}

.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}

.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 11px;
  height: 11px;
  left: 3px;
  top: 3px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: $orange;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
  opacity: 0;
}

.radio input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}

.radio.radio-inline {
  margin-top: 0;
}

.checkbox {
  padding-left: 20px;
}

.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
  font-weight: 300;
  line-height: 16px;
}

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: $orange;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\f00c";
}

.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eee;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

#check-disclaimer {
  margin-right:4px;
}
#check-disclaimer + label {
  vertical-align:-2px;
}
#sendForm[disabled] {
  background:$orange;
}

.mce-tinymce {
  margin-bottom: 10px !important;
}

.alert {
  border-radius:0;
}
.alert-success {
  background:#4EC481;
  color:#006A2E;
  border-color:#006A2E;
}