.slider-container {
  width:100%;
  padding-top:35px;
  position:relative;
}

#slider {
  height:600px;
  min-height:600px;
  position:relative;
  overflow:hidden;
  z-index:1;

  .owl-item {
    width:100%;
    height:600px;

    .slide {
      position:relative;
      width:100%;
      height:600px;
      min-height:600px;
      background-size:cover;
      background-position: top center;

      .owl--text {
        background:rgba(255,255,255,0.6);
        text-align:center;
        position:absolute;
        padding-top:15px;
        bottom:0;
        width:1140px;
        height:136px;
        left:0;
        right:0;
        margin:0 auto;
        border-top-right-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -moz-border-radius-topright: 5px;
        border-top-left-radius: 5px;
        -webkit-border-top-left-radius: 5px;
        -moz-border-radius-topright: 5px;
        h2, h3  {
          margin:0;
          padding:0;
          color:$brown;
        }
        h2 {
          font-family: $font-title;
          font-weight:bold;
          font-size:20px;
        }
        h3 {
          font-family: $font-standard;
          font-weight:400;
          font-size:16px;
          margin-top:3px;
        }
        p {
          margin:3px 0 4px 0;
          color:$orange;
          font-family: $font-standard;
          font-weight:bold;
          font-size:20px;
          small {
            font-size:12px;
          }
        }
        a {
          color:white;
          background:$brown;
          border-radius:15px;
          padding:5px 25px 6px 25px;
          &:hover,
          &:focus {
            text-decoration: none;
            background:$orange;
          }
        }
      }

    }
  }
  .owl-controls {
    text-align: center;
    margin-top:-170px;
    z-index:101;
    .owl-dots {
      position:relative;
      /*
      height:30px;
      width:70px;
      position:absolute;
      bottom:141px;
      left:0;
      right:0;
      margin:0 auto;
      display: inline-block;
      zoom: 1;
      */
      .owl-dot {
        width: 20px;
        height: 20px;
        margin: 0 5px 0 0;
        background: white;
        border:3px solid white;
        display: inline-block;

        -webkit-backface-visibility: visible;
        -webkit-transition: opacity 200ms ease;
        -moz-transition: opacity 200ms ease;
        -ms-transition: opacity 200ms ease;
        -o-transition: opacity 200ms ease;
        transition: opacity 200ms ease;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        border-radius: 40px;
        &.active {
          background:$orange;
        }
        &:last-child {
          margin:0;
        }
      }
    }
  }
}