.topButtons {
  padding: 55px 15px 15px 15px;
  background: $grey;

  .btn-default {
    border: 0;
    background: $grey;
    font-size: 16px;

    i {
      margin-right: 3px;
      font-size: 15px;
    }

    &:hover,
    &:focus {
      color: $orange;
    }
  }
}

.addButton {
  position: absolute;
  top: 10px;
}

#notities {
  .topButtons {
    padding: 15px;
  }
}

.bottomButtons {
  padding-bottom: 30px;
  float:left;

  .btn-default {
    i {
      margin-right: 3px;
    }
  }

}

.formButtons {
  overflow: auto;
  margin-top: 10px;
  i.fa-arrow-left {
    margin-right:3px;
  }
}

.btn-danger {
  i.fa-trash {
    font-size: 14px;
    margin-right: 4px;
  }
}

button.btn-primary,
a.btn-primary {
  background: $orange;
  border: 0;
  color: white;
  font-size: 16px;
  padding: 8px 15px 10px 15px;
  i.fa-pencil,
  i.fa-save,
  i.fa-plus {
    font-size: 14px;
    margin-right: 4px;
  }
  i.fa-plus {
    font-size: 12px;
  }

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background: $brown;
    outline: 0;
  }
}
.btn-primary[disabled] {
  background:$orange;
}

.dropdown-toggle {
  padding: 9px 12px;
}

.btn {
  border-radius: 0;
  padding: 8px 15px 10px 15px;

  &.pull-right {
    margin-left: 10px;
  }
}