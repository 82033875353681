@import 'partials/variables';
@import 'partials/tables';
@import 'partials/buttons';
@import 'partials/forms';
@import 'partials/alerts';
@import 'partials/tabs';

@import 'layouts/partials/main';
@import 'layouts/partials/header';
@import 'layouts/partials/footer';
@import 'layouts/partials/nav';
@import 'layouts/partials/searchbar';
@import 'layouts/partials/carousel';
@import 'layouts/partials/house';
@import 'layouts/partials/compare';
@import 'layouts/partials/detail';
@import 'layouts/partials/banner';
@import 'layouts/partials/sidebar';
@import 'layouts/partials/pagination';
@import 'layouts/partials/faq';
@import 'layouts/partials/profiles';

@import 'layouts/responsive/md';
@import 'layouts/responsive/sm';
@import 'layouts/responsive/xs';
@import 'layouts/responsive/min';
@import 'layouts/responsive/ipad';





li.dropdown:hover .dropdown-menu {
  display: block;
}

.full-container {
  width:100%;
  height:100%;
  background:$grey;
  position:relative;
}

.panel-default {
  border-radius:0;
  border:0;
  float:left;
  width:100%;
  .panel-heading {
    border-radius:0;
    background:$brown;
    color:white;
  }
}
.logo-icon {
  margin:0 auto 15px auto;
  display:block;
  width:100%;
  float:left;
  .logo,
  span {
    float:left;
    display:block;
  }
  .logo {
    max-width:80px;
  }
  span {
    color:$brown;
    margin-left:15px;
    margin-top:14px;
    font-size:42px;
    font-family:$font-title;
  }
}