@media only screen and (min-width : 768px) {
  .navbar-default {
    background:white;
    border:0;
    margin:0;
    padding:20px 0;
    position:absolute;
    width:100%;
    height:76px;
    left:0;
    right:0;
    bottom:0;
    z-index:2000;
    border-radius:0;


    #navbar {
      padding-left:0;
      padding-right:0;

      .navbar-nav {
        li {
          a {
            font-family: $font-standard;
            border-left:1px solid $brown;
            padding:0 22px;
            color:$brown;
            span {
              display:block;
              text-align:center;
              &:first-child {
                text-transform:uppercase;
                font-weight:900;
                font-size:20px;
                line-height:20px;
                letter-spacing:-1px;
              }
              &:last-child {
                font-size:16px;
                line-height:16px;
                font-weight: 400;
                text-transform: lowercase;
              }
            }
            &:hover,
            &:focus {
              background:transparent;
              color:$orange;
            }
          }
          &:last-child {
            a {
              color:$orange;
              padding-right:0;
            }
          }
          &.active {
            a {
              background:transparent;
              color:$orange;
            }
          }
        }
      }
    }
    &.fixed {
      position:fixed;
      top: 0;
      z-index: 2010;
      width: 100%;
      height:76px;
      box-shadow:0 0 3px #333;
    }
  }
}

@media only screen and (min-width : 992px) {
  .navbar-default {
    margin:0;
    padding:32px 0;
    height:106px;
    /*margin:8px 0 24px 0;*/
    #navbar {
      .navbar-nav {
        li {
          a {
            padding:0 35px;
            span {
              &:first-child {
                font-size:24px;
                line-height:24px;
              }
              &:last-child {
                font-size:18px;
                line-height:18px;
              }
            }
          }
        }
      }
    }
    &.fixed {
      position:fixed;
      top: 0;
      z-index: 2010;
      width: 100%;
      height:106px;
      box-shadow:0 0 3px #333;
    }
  }
}

@media only screen and (min-width : 1200px) {
  .navbar-default {
    margin:0;
    padding:32px 0;
    #navbar {
      .navbar-nav {
        li {
          a {
            padding:0 44px;
            span {
              &:first-child {
                font-size:32px;
                line-height:32px;
              }
              &:last-child {
                font-size:20px;
                line-height:20px;
              }
            }
          }
        }
      }
    }
  }
}






