.footer-container {
  width:100%;
  float:left;
  display:block;
}
.footer-columns {
  float:left;
  width:100%;
  padding-bottom:30px;
  .col {
    position:relative;
    width:100%;
    float:left;
    color:$font-color;
    h4 {
      position:relative;
      z-index:10;
      margin:0 0 30px 0;
      padding:0;
      display:inline-block;
      overflow:hidden;
      background:white;
      padding-right:20px;
      line-height:20px;
      font-size:20px;
      color:$brown;
    }

    p {
      color:$font-color;
    }
    ul.contact {
      margin-top:0px;
      li  {
        &:last-child {
          margin-top:15px;
        }
      }
    }
    ul.fa-ul {
      li {
        line-height:28px;
        a {
          color:$font-color;
          &:hover,
          &:focus {
            text-decoration: none;
            color:$orange;
          }
        }

        i {
          &.fa-li {
            left:-3em;
            width:3em;
            line-height:28px;
          }
          &.fa-envelope-o {
            font-size:16px;
            top:0;
          }
          &.fa-phone {
            font-size:17px;
            top:1px;
            left:-2.94em;
          }
          &.fa-map-marker {
            font-size:20px;
            left:-2.76em;
            top:-2px;
          }
        }
      }
    }

    ul.partners {
      margin:0;
      padding:0;
      list-style:none;
      li {
        margin: 0 0 15px 0;
        width:100%;
        float:left;
      }
    }

    &:after {
      content:"";
      background:$brown;
      position:absolute;
      right:0px;
      top:11px;
      width:100%;
      height:1px;
      z-index:0;
    }
  }
}


footer {
  background:$brown;
  color:white;
  width:100%;
  float:left;
  padding:15px 0 0 0;

  .copyright {
    float:left;
    padding-top:9px;
    a {
      color:white;
      &:hover,
      &:focus {
        color:$orange;
      }
    }
  }
  ul.social {
    list-style:none;
    float:right;
    margin:0;
    padding:0;
    li {
      float:left;
      margin-left:10px;
      a {
        background:white;
        border-radius:50%;
        width:40px;
        height:40px;
        display:block;
        font-size:20px;
        text-align:center;
        padding-top:6px;
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform:rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .3s;
        transition: .3s;
        i {
          color:$brown;
        }

        &:hover,
        &:focus {
          background:$orange;
          -ms-transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          -moz-transform:rotate(360deg);
          transform: rotate(360deg);
          i {
            color:$brownShadow;
          }
        }
      }
    }
  }
}