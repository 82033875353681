@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)  {

  #slider .owl-item .slide .owl--text {
    width:100%;
    border-radius:0;
  }

  .s-houses {
    .houses {
      .house {
        .owl--text {
          ul.options {
            li {
              span {
                display:none;
                &.badge {
                  display:block;
                }
              }
            }
          }
        }
      }
    }
  }
  #sidebar-filter.affix-top {
    position:static !important;
  }
  #sidebar-filter.affix {
    position:static !important;
  }
  .btn {
    padding:8px 10px 10px 10px;
  }

  #kaart .van .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  #kaart .tot .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  #kaart .s-oppervlak .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  #kaart .s-slaapkamers .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  #kaart .s-woningtype .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  .col-sidebar .van .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  .col-sidebar .tot .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  .col-sidebar .s-oppervlak .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  .col-sidebar .s-slaapkamers .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  .col-sidebar .s-woningtype .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  #inschrijven .van .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  #inschrijven .tot .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  #inschrijven .s-oppervlak .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  #inschrijven .s-slaapkamers .bootstrap-select.btn-group .dropdown-toggle .filter-option,
  #inschrijven .s-woningtype .bootstrap-select.btn-group .dropdown-toggle .filter-option {
    padding-left:35px;
  }

  #kaart .s-search .form-control,
  .col-sidebar .s-search .form-control,
  #inschrijven .s-search .form-control {
    padding-left:45px;
  }
  #kaart .s-search:before,
  .col-sidebar .s-search:before,
  #inschrijven .s-search:before {
    left:18px;
  }

  .tot {
    .bootstrap-select {
      .dropdown-toggle {
        .filter-option {
          padding-left:35px !important;
          &:before {
            content:"tot:" !important;
          }
        }
        /*
        .bs-caret {
          .caret {
            right:0;
          }
        } */
      }
    }
  }
  #kaart {
    #sidebar-filter {
      display: none
    }
  }
}