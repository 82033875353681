#kaart, .col-sidebar, #inschrijven {
  padding-right: 45px;

  .sidebar {
    background: rgba(242, 245, 247, 1);
    border: 1px solid rgba(242, 245, 247, 0.7);
    width: 100%;
    min-height: 100px;
    margin-top: 60px;
    padding: 15px;
    font-size: 16px;
    font-family: $font-standard;

    h3 {
      margin: 0 0 15px 0;
      padding: 0;
      font-weight: bold;
    }
    label {
      &.title {
        color: $brown;
        font-weight: normal;
        font-family: $font-standard;
        font-size: 16px;
        margin: 15px 0 3px 0;
        padding: 0;
      }
    }
    .mTop {
      margin-top:20px;
    }
  }

  .form-group {
    margin: 0;
  }

  .filter-option {
    margin-top: 3px;
  }

  .bootstrap-select {
    &.btn-group {
      .dropdown-toggle {
        .filter-option {
          font-size: 16px;
          font-family: $font-standard;
          font-weight: 300;
          color: $font-color;
        }
      }
    }
  }

  .s-search {
    position:relative;
    .form-control {
      padding-left:56px;
    }
    &:before {
      content:"\f041";
      font-family: FontAwesome;
      position: absolute;
      left:22px;
      top:6px;
      font-size:20px;
      color:#ccc;
    }
  }

  .van, .tot, .s-oppervlak, .s-slaapkamers, .s-woningtype {
    margin: 0 0 3px;
    .bootstrap-select {
      &.btn-group {
        .dropdown-toggle {
          .filter-option {
            padding-left: 40px;
            position: relative;
            font-size: 16px;
            font-family: $font-standard;
            &:before {
              content: "van:";
              position: absolute;
              font-size: 16px;
              left: 0;
              top: 0;
              color: #ccc;
            }
          }
        }
      }
    }
  }

  .s-woningtype {
    margin-bottom: 0;
    .bootstrap-select {
      &.btn-group {
        .dropdown-toggle {
          .filter-option {
            &:before {
              content: "\f013";
              top:0px;
              font: normal normal normal 20px/1 'BrixIziKotw';
            }
          }
        }
      }
    }
  }

  .s-slaapkamers {
    margin-bottom: 0;
    .bootstrap-select {
      &.btn-group {
        .dropdown-toggle {
          .filter-option {
            &:before {
              content: "\f00f";
              top:6px;
              font: normal normal normal 14px/1 'BrixIziKotw';
            }
          }
        }
      }
    }
  }

  .s-oppervlak {
    margin-bottom: 0;
    .bootstrap-select {
      &.btn-group {
        .dropdown-toggle {
          .filter-option {
            &:before {
              content: "\f00d";
              top:0px;
              left:2px;
              padding:3px;
              border:1px solid #ccc;
              font: normal normal normal 14px/1 'BrixIziKotw';
            }
          }
        }
      }
    }
  }

  .tot {
    margin-bottom: 0;
    .bootstrap-select {
      &.btn-group {
        .dropdown-toggle {
          .filter-option {
            &:before {
              content: "tot:";
            }
          }
        }
      }
    }
  }
  .tot.till {
    .bootstrap-select {
      &.btn-group {
        .dropdown-toggle {
          .filter-option {
            &:before {
              content: "to:";
            }
          }
        }
      }
    }
  }
  .van.from {
    .bootstrap-select {
      &.btn-group {
        .dropdown-toggle {
          .filter-option {
            &:before {
              content: "from:";
            }
          }
        }
      }
    }
  }
  .woonoppervlakte {
    .bootstrap-select {
      &.btn-group {
        .dropdown-toggle {
          .filter-option {
            &:before {
              content: "van:";
            }
          }
        }
      }
    }
  }
  .bootstrap-select {
    &.btn-group {
      .dropdown-toggle {
        color: $font-color;
      }
    }
  }
}

a.reset-filter {
  padding-top: 8px;
  padding-right: 7px;
  text-decoration: none;

  i.fa {
    font-size: 18px;
    padding-right: 3px;
    vertical-align: -2px;
  }
}

#inschrijven {
  padding: 0 0 20px;
  float: left;

  .form-group {
    margin: 3px 0 8px;

    .filter-option {
      margin-top: 0;
    }
  }

  .group-prijs {
    display: none;

    p {
      margin: 10px 0 0;
    }
  }

  .van, .tot {
    margin: 0;
  }
}

#kaart, .col-sidebar {
  .van, .tot {
    width: 100%;
  }
}