.contact {
  #faq_list {
    margin-bottom:30px;
    width:100%;
    float:left;
  }
  .panel-group {
    .panel {
      background:white;
      border-color:#ddd;
      border-radius:0;
      box-shadow:none;
      border-bottom:1px solid #ddd;
      .panel-heading {
        background:white;
        padding:15px 15px 15px 0;
        font-size:16px;
        font-weight:normal;
        border:0;
      }
      .panel-body {
        border-color:#ddd;
        padding:10px 0;
        p, ul, ol {
          margin-bottom:17px;
          &:last-child {
            margin:0;
          }
        }
        ul {
          padding-left:17px;
        }
      }
    }
    .panel + .panel {
      margin-top:0;
    }
  }
  h4.panel-title {
    font-size:16px;
    text-transform:inherit;
    font-family: $font-standard;
    font-weight:normal;
    a {
      color:$brownShadow;
      position:relative;
      width:100%;
      display:block;
      i {
        position:absolute;
        right:-5px;
        top:2px;
        width:11px;
        height:11px;
      }
      &:hover,
      &:focus {
        text-decoration:none;
        color:$brownShadow;
      }
    }
  }
}
