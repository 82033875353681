.nav-tabs {
  margin: 25px 0 11px 0;
  li {
    a {
      color:$orange;
    }
  }
}
.tab-pane {
  position:relative;
}