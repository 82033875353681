.pagination {
  margin:0;
  li {

    a {
      font-size:16px;
      background:transparent;
      border:1px solid transparent;
      margin-right:5px;
      &:hover,
      &:focus {
        background:transparent;
        color:white;
        border:1px solid white;
      }
    }
    &.active {
      a {
        background:transparent;
        border:1px solid $orange;
        color:$orange;
      }
    }
    &:first-child,
    &:last-child {
      a {
        border-radius:0;
      }
    }
    &:last-child {
      a {
        margin-right:0;
      }
    }
  }
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  background:transparent;
  border:0;
  border-radius:0;
  color:$orange;
  opacity:0.5;
}