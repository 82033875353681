#compare {
  width:100%;
  float:left;
  position:relative;
  background:#f2f5f7;
  padding:45px 45px 15px 45px;
  margin:60px 0 0 0;
  h3 {
    margin:0;
    padding:0;
  }
  ul.compare-houses {
    margin:30px 0 0 0;
    padding:0;
    list-style: none;
    li {
      float:left;
      width:33.333333%;
      position:relative;
      padding:0 5px;
      margin:0 0 30px 0;
      display:block;
      a {
        position:relative;
        display:block;
        float:left;
        width:100%;
        transition:ease all 0.25s;
        z-index:0;
        &.nieuw,
        &.verwacht {
          &:before {
            content: "";
            position: absolute;
            z-index: 0;
            left: -4px;
            top: 11px;
            width: 15px;
            height: 15px;
            transform: rotate(60deg);
            background:#d5770b;
          }
        }
        .status {
          background: $orange;
          padding: 6px 10px 8px 10px;
          position:absolute;
          top:16px;
          left:-7px;
          font-size:24px;
          z-index:10;
          p {
            color: white;
            margin: 0;
          }
        }
        span.image {
          opacity:1;
          background:black;
          position:relative;
          z-index:5;
          width:100%;
          float:left;
          display:block;
          transition:ease all 0.25s;
          &:before {
            content:"\f06e";
            font-family: FontAwesome;
            position:absolute;
            left:50%;
            top:50%;
            transform:translate(-50%,-50%);
            text-align:center;
            color:white;
            font-size:40px;
            z-index:10;
            opacity:0;
            transition:ease all 0.25s;
          }
          &:after {
            content: "";
            width: 100%;
            height: 80px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #48382a 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#48382a', GradientType=0);
            z-index: 0;
            transition: 0.2s;
          }
        }
        span.address {
          float:left;
          width:100%;
          background:white;
          padding:10px;
          position:relative;
          z-index:11;
          h4 {
            margin:0;
            font-size:20px;
          }
          p  {
            margin:0;
          }
        }
        &.kotk-stamp {
          &:before {
            content: "klaaromteklussen";
            background:#8E8575;
            color:white;
            font-size:24px;
            width: 200px;
            padding:6px 10px 8px 10px;
            left: -7px;
            top: 16px;
            position: absolute;
            z-index: 101;
            transform: rotate(0deg);
            transition: 0.25s;
          }
          &:after {
            content: "";
            position: absolute;
            z-index: 0;
            left: -5px;
            top: 10px;
            width: 15px;
            height: 15px;
            transform: rotate(60deg);
            background:#655E4E;
          }
        }

        &:hover,
        &:focus {
          text-decoration:none;
          span.image {
            &:before {
              opacity:1;
            }
            &:after {
              height: 100%;
            }
          }
          span.address {
            text-decoration:none;
          }
          &:before,
          &:after {
            opacity:1;
          }
        }
      }
    }
  }


}