.fixed-table-pagination {
  .pagination a {
    padding:7px 12px;
  }
}

.bootstrap-table {

  .fixed-table-container {
    border-radius: 0;
    border: 0;
  }
  .table-logs,
  table.table-row-links {
    border-radius: 0;
    border: 0;
    thead {
      tr {
        th {
          border-radius: 0 !important;
          background: $brown;
          color: white;
          font-size: $font-16;
          font-weight: normal;
          text-shadow: none;
          text-transform: uppercase;
          border-color: white;
          border: 0;
          border-right: 1px solid #655343;
          &:first-child {
            .th-inner {
              padding-left: 14px;
            }
          }
          .th-inner {
            padding: 14px 8px;
          }
          .desc {
            margin-top: 0;
            background-image: url(/images/desc.png);
          }
          .asc {
            background-image: url(/images/asc.png);
          }
        }
      }
    }
    tbody {
      border: 0;
      tr {
        transition: all 200ms;
        cursor: pointer;

        td {
          position: relative;
          border: 0;
          border-top: 1px solid #eee;
          &:first-child {
            padding-left: 14px;
          }
        }
        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      tr:hover {
        background-color: #dfe2e5 !important;
      }

      tr.row-grey {
        background-color: $grey;
        color: #ccc;
      }

      tr.row-new {
        color: #ccc;
        background: $grey;
      }

      tr.row-green {
        background-color: rgba(105, 149, 1, 0.15);
      }

      tr.row-red {
        background-color: rgba(149, 15, 0, 0.10);
      }

      tr.row-yellow {
        background-color: rgba(255, 249, 0, 0.15);
      }
    }
  }
}

.bootstrap-table .table:not(.table-condensed),
.bootstrap-table .table:not(.table-condensed) > tbody > tr > td,
.bootstrap-table .table:not(.table-condensed) > tbody > tr > th,
.bootstrap-table .table:not(.table-condensed) > tfoot > tr > td,
.bootstrap-table .table:not(.table-condensed) > tfoot > tr > th,
.bootstrap-table .table:not(.table-condensed) > thead > tr > td {
  padding: 14px 8px;
}

.fixed-table-pagination {
  margin-top: 20px;
  .pagination-info {
    color: $brown;
  }
  .pagination {
    margin-bottom: 0;
  }
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background: $orange;
  border-color: $orange;
  color: white;
}

.pagination > li > a,
.pagination > li > span {
  color: $orange;
}

.pagination {
  li.page-number {
    a {
      padding: 7px 12px 7px 12px;
      &:hover,
      &:focus {
        color: $orange;
      }
    }
    &.active {
      a {
        &:hover,
        &:focus {
          color:white;
        }
      }
    }
  }
  .page-next,
  .page-pre {
    a {
      padding: 0px 12px 3px 12px;
      font-size: 22px;
      &:hover,
      &:focus {
        color: $orange;
      }
    }
  }
}

#aanbod {
  th[data-field=websites] {
    width: 150px;
  }
  th[data-field=prijs] {
    width: 150px;
  }
  th[data-field=online] {
    width: 180px;
  }
  th[data-field=gewijzigd] {
    width: 150px;
  }
}

#zoekers {
  th[data-field=naam] {
    width: 15%;
  }
  th[data-field=email] {
    width: 18%;
  }
  th[data-field=koopwoning] {
    width: 13%;
  }
  th[data-field=huurwoning] {
    width: 13%;
  }
  th[data-field=websites] {
    width: 140px;
  }
}

#makelaars {

  th[data-field=plaats] {
    width: 20%;
  }
  th[data-field=websites] {
    width: 180px;
  }
  th[data-field=koppeling] {
    width: 180px;
  }
  th[data-field=collega] {
    width: 20%;
  }

}

#hypothekers {
  th[data-field=kantoor],
  th[data-field=adviseur],
  th[data-field=telefoon],
  th[data-field=email] {
    width: 25%;
  }
}

#volgen {
  th[data-field=websites] {
    width: 180px;
  }
  th[data-field=adres] {
    width: 25%;
  }
  th[data-field=plaats] {
    width: 25%;
  }
  th[data-field=makelaar] {
    width: 25%;
  }
}

.fixed-table-toolbar {
  .search {
    margin-bottom: 22px;
  }
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
  background: white;
}
