#huur-home {
  padding-top: 0;
}

.s-houses {
  position: relative;

  #form-order {
    width: 220px;
    position: absolute;
    top: -40px;
    right: 25px;
    display: block;

    .btn {
      background: rgba(0, 0, 0, 0.2);
      border-color: white;
      color: white;
    }
    .dropdown-menu {
      border-radius:0;
      padding:0;
    }
    .dropdown-menu>li>a {
      padding:6px 20px;
    }
    .dropdown-menu>li>a:focus,
    .dropdown-menu>li>a:hover {
      background:$orange;
    }
  }

  h2 {
    margin: 0;
    padding: 0;
    color: $orange;
    text-transform: initial;
    a {
      text-decoration:none;
      &:hover,
      &:focus {
        text-decoration:none;
      }
    }
  }
  .houses {
    height: 485px;
    margin: 50px 0 50px -8px;
    .owl-stage-outer {
      height: 505px;
      padding: 10px 0;
    }
    .item,
    .owl-item,
    .owl-slide {
      padding: 0 10px 0 6px;
      margin: 0;
      width: 100%;
      height: 485px;
    }
    .house {
      position: relative;
      background: white;
      margin: 0 0 3px 0;
      display: block;
      width: 100%;
      float: left;
      box-shadow: 0 0 3px 0 $brownShadow;
      transition: 0.25s;
      z-index: 1;
      .image {
        position: relative;
        width: 100%;
        float: left;
        display: block;
        .status,
        .price {
          position: absolute;
          left: 15px;
          color: $orange;
          font-family: $font-standard;
          font-weight: 300;
          font-size: 20px;
          line-height: 18px;
          z-index: 10;
          small {
            font-size: 12px;
          }
        }
        .price {
          bottom: 12px;
        }
        .status {
          top: 15px;
          font-size: 24px;
          margin-left: -25px;
          p {
            margin: 0;
          }
        }

        &:before {
          content: "";
          background-image: url(/images/stamp.png);
          background-repeat: no-repeat;
          width: 59px;
          height: 59px;
          right: 15px;
          bottom: 12px;
          position: absolute;
          z-index: 10;
          transform: rotate(0deg);
          transition: 0.25s;
        }
        &:after {
          content: "";
          width: 100%;
          height: 80px;
          position: absolute;
          bottom: 0;
          left: 0;
          background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(72, 56, 42, 1) 100%);
          background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(72, 56, 42, 1) 100%);
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(72, 56, 42, 1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#48382a', GradientType=0);
          z-index: 0;
          transition: 0.2s;
        }
      }

      .owl--text {
        .text-inner {
          padding: 15px;
          float: left;
          width: 100%;
          background: white;
        }
        .title {
          float: left;
          width: 100%;
        }
        h3, h4 {
          margin: 0;
          padding: 0;
        }
        h3 {
          font-family: $font-title;
          font-weight: bold;
          font-size: $font-20;
          margin-bottom: 3px;
        }
        h4 {
          font-family: $font-standard;
          color: $orange;
          font-size: $font-16;
        }
        ul {
          &.options {
            margin: 25px 0 0 0;
            padding: 0;
            float: left;
            width: 100%;
            position: relative;
            list-style: none;
            li {
              width: 100%;
              display: block;
              float: left;
              color: $font-color;
              font-size: $font-16;
              margin: 0 0 10px 0;
              padding: 0 0 0 40px;
              position: relative;
              span {
                float: left;
                &.badge {
                  float: right;
                  color: $font-color;
                  background: $grey;
                  font-size: $font-16;
                  font-weight: 400;
                }
              }
              &.kamers {
                &:after {
                  content: "\f010";
                  top: 0px;
                  left: 6px;
                  font-size: 23px;
                }
              }
              &.woningtype {
                &:after {
                  content: "\f013";
                  font-size: 21px;
                  top: 2px;
                  left: 0;
                }
              }
              &.oppervlakte {
                /*
                span {
                  &.badge {
                    background: transparent;
                  }
                }*/
                &:after {
                  content: "\f00d";
                  border: 1px solid $font-color;
                  width: 20px;
                  height: 20px;
                  text-align: center;
                  padding-top: 2px;
                  top: 2px;
                  left: 3px;
                }
              }
              &:after {
                font: normal normal normal 14px/1 'BrixIziKotw';
                position: absolute;
                width: 30px;
                height: 30px;
              }
            }
          }
        }
      }
      .view {
        background-color: white;
        border-top: 1px solid $grey;
        color: $orange;
        float: right;
        text-align: right;
        padding: 10px 15px 12px 15px;
        width: 100%;
        position: relative;

        i.fa {
          padding-right: 3px;
        }
      }

      &:after {
        content: "";
        position: absolute;
        display: block;
        background: $grey;
        height: calc((100% - 42px));
        width: 17px;
        top: 0;
        right: -15px;
        transform: skew(2deg, 0deg);
        transform-origin: 0 100%;
        z-index: -1;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        box-shadow: 0 0 5px 0 #333;
        .image {
          &:before {
            transform: rotate(-0deg);
          }
          &:after {
            height: 100%;
          }
        }
      }
    }
    .owl-controls {
      position: absolute;
      top: -95px;
      right: 0;
    }
    .owl-nav {
      /*
      width: 84px;
      height: 36px;
      position: relative;
      */
      position: absolute;
      top: -95px;
      right: 0;
      .owl-prev,
      .owl-next {
        float: left;
        margin: 0 5px 0 0;
        width: 36px;
        text-align: center;
        height: 36px;
        display: block;
        border: 1px solid transparent;
        position: relative;
        &:after {
          position: absolute;
          font-family: FontAwesome;
          color: $orange;
          font-size: 20px;
          width: 36px;
          height: 22px;
          line-height: 22px;
          top: 6px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
        &:hover,
        &:focus {
          border: 1px solid white;
          &:after {
            color: white;
          }
        }
      }
      .owl-prev {
        &:after {
          content: "\f060";
        }
      }
      .owl-next {
        &:after {
          content: "\f061";
        }
      }
    }
    .verwacht {
      /*.view {
        background:rgba(255,231,176,1);
        border-color:#F1B007;
      }*/
      .image {
        .status {
          background: #f1b007;
          padding: 6px 10px 8px 10px;
          p {
            color: #fff;
            margin: 0;
          }
        }
      }
      .owl--text {
        .text-inner {
          /*background:rgba(255,231,176,1);*/
        }
        ul.options {
          li {
            span {
              color: #666;
            }
            /*
            span.badge {
              background:#F1B007;
              color:white;
            }*/
            &:last-child {
              /*
              span.badge {
                color:#F1B007;
              }*/
            }
            &:after {
              color: #666;
            }
          }
        }
      }
      &:before {
        background: #d19613;
      }
    }
    .verhuurd,
    .verkocht,
    .voorbehoud {
      /*.view {
        background:rgba(210,242,255,1);
        border-color:#0C6A98;
      }*/
      .image {
        .status {
          background: $brown;
          padding: 6px 10px 8px 10px;
          p {
            color: white;
            margin: 0;
          }
        }
      }
      .owl--text {
        .text-inner {
          /*background:rgba(210,242,255,1); */
        }
        ul.options {
          li {
            span {
              color: #666;
            }
            /*
            span.badge {
              background:#0C6A98;
              color:white;
            }*/
            &:last-child {
              /*
              span.badge {
                color:#0C6A98;
              }*/
            }
            &:after {
              color: #666;
            }
          }
        }
      }
      &:before {
        background: $brownShadow;
      }
    }
    .nieuw {
      /*
      .view {
        background:rgba(194,249,225,1);
        border-color:#05A464;
      }*/
      .image {
        .status {
          background: $orange;
          padding: 6px 10px 8px 10px;
          p {
            color: white;
            margin: 0;
          }
        }
      }
      .owl--text {
        .text-inner {
          /*background:rgba(194,249,225,1); */
        }
        ul.options {
          li {
            span {
              color: #666;
            }
            /*
            span.badge {
              background:#05A464;
              color:white;
            }*/
            &:last-child {
              /*
              span.badge {
                color:#05A464;
              }*/
            }
            &:after {
              color: #666;
            }
          }
        }
      }
      &:before {
        background: #d5770b;
      }
    }
    .verwacht,
    .voorbehoud {
      .owl--text {
        ul.options {
          li {
            span {
              color: $font-color;
            }
            &:after {
              color: $font-color;
            }
          }
        }
      }
      &:before {
        background: $purpleDark;
      }
    }
    .nieuw,
    .voorbehoud,
    .verkocht,
    .verhuurd,
    .verwacht {
      &:before {
        content: "";
        position: absolute;
        z-index: 0;
        left: -7px;
        top: 10px;
        width: 15px;
        height: 15px;
        -ms-transform: rotate(60deg); /* IE 9 */
        -webkit-transform: rotate(60deg); /* Chrome, Safari, Opera */
        transform: rotate(60deg);
      }
    }
  }
}

.view-all {
  float: left;
  position: relative;
  width: 100%;
  overflow: hidden;
  a {
    font-family: $font-title;
    font-weight: bold;
    color: white;
    font-size: 20px;
    text-align: center;
    background: transparent;
    display: block;
    width: 270px;
    margin: 0 auto;
    position: relative;
    z-index: 10;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 1000px;
      height: 1px;
      top: 15px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: white;
      z-index: 0;
    }
    &:before {
      left: -1300px;
    }
    &:after {
      right: -1300px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: $orange;
    }

  }
}

#koopwoningen {
  .houses {
    .row-houses {
      .col-house {
        .house {
          margin-bottom: 45px;
        }
      }
    }
  }
}

ul {
  &.kenmerken {
    margin: 25px 0 0 0;
    padding: 0;
    float: left;
    width: 100%;
    position: relative;
    list-style: none;
    li {
      width: 100%;
      display: block;
      float: left;
      color: $font-color;
      font-size: $font-16;
      margin: 0 0 10px 0;
      padding: 0 0 0 40px;
      position: relative;
      span {
        float: left;
        &.badge {
          float: right;
          color: $font-color;
          background: $grey;
          font-size: $font-16;
          font-weight: 400;
        }
      }
      &.kamers {
        &:after {
          content: "\f010";
          top: 0px;
          left: 6px;
          font-size: 23px;
        }
      }
      &.woningtype {
        &:after {
          content: "\f013";
          font-size: 21px;
          top: 2px;
          left: 0;
        }
      }
      &.oppervlakte {
        /*
        span {
          &.badge {
            background: transparent;
          }
        }*/
        &:after {
          content: "\f00d";
          border: 1px solid $font-color;
          width: 20px;
          height: 20px;
          text-align: center;
          padding-top: 2px;
          top: 2px;
          left: 3px;
        }
      }
      &:after {
        font: normal normal normal 14px/1 'BrixIziKotw';
        position: absolute;
        width: 30px;
        height: 30px;
      }
    }
  }
}