.search-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  z-index: 3;
}

.searchbar {
  width: 100%;
  padding: 0 0 0 75px;
  height: 70px;
  background: $grey;
  border: 1px solid $brown;
  position: relative;
  z-index: 3;
  box-shadow: inset 0 0 3px rgba(72, 56, 42, 0.45);

  .form-group {
    margin:18px 0 0 0;
  }

  .col-search {
    width: 40%;
    float: left;

    .form-control {
      background: transparent;
      box-shadow: none;
      border: 0;
      border-radius: 0;
      font-size:21px;
      border-left: 1px solid $brown;
      height: 32px;
      padding:0 15px;
      transition: all 0.2s ease;

      &:focus {
        border-width: 1px;
      }
    }
  }
  .col-sort,
  .col-price {
    margin: 0;
    float: left;
    width: 15%;
    .bootstrap-select {
      &.btn-group {
        .dropdown-toggle {
          padding:0 5px 0 15px;
          height:32px;
          background:transparent;
          border:0;
          font-size:16px;
          border-left:1px solid #b6b5b5;
          color:#b6b5b5;
          font-weight:300;
          .filter-option {
            position: relative;
            font-size: 16px;
            color:#b6b5b5;
          }
          &:focus,
          &:active {
            box-shadow:none;
          }
        }
        &.open {
          .dropdown-toggle {
            box-shadow:inset 0 0px 0px rgba(0,0,0,.125) !important;
          }
        }
      }
    }
  }
  .tot,
  .vanaf {
    .bootstrap-select {
      &.btn-group {
        .dropdown-toggle {
          .filter-option {
            padding-left:35px;
            &:before {
              content: "van:";
              position: absolute;
              font-size: 16px;
              left: 0;
              top: 0px;
              color: #b6b5b5;
            }
          }
        }
      }
    }
  }

  .tot {
    .bootstrap-select {
      &.btn-group {
        .dropdown-toggle {
          .filter-option {
            padding-left:30px;
            &:before {
              content: "tot:";
            }
          }
        }
      }
    }
  }

  .tot.till {
    .bootstrap-select {
      &.btn-group {
        .dropdown-toggle {
          .filter-option {
            &:before {
              content: "to:";
            }
          }
        }
      }
    }
  }

  .btn-submit {
    margin:1px 1px 0 0;
    border:0;
    box-shadow:none;
    background:$orange;
    color:white;
    height:66px;
    width:12%;
    display:block;
    float:right;
    text-align:center;
    font-size:21px;
    transition: 0.25s;

    &:hover,
    &:focus {
      background:$brown;
      color:white;
    }
  }
  &:before {
    content: "\f002";
    font-family: FontAwesome;
    font-size: 34px;
    position: absolute;
    left: 20px;
    top: 14px;
    color: $brown;
    width: 50px;
    height: 70px;
    line-height: 34px;
  }
}