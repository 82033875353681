@media only screen and (max-width: 991px) {
  .container {
    width:100%;
  }
  header {
    height: 202px;
    .container {
      width: 100%;
    }
    .header-bar {
      height: 60px;
      .navbar-brand {
        max-width: 140px;
        text-shadow: none;
        span {
          top: 11px;
          font-size: 36px;
          line-height: 36px;
        }
      }
      .slogan {
        display: none;
      }
    }
  }
  #slider {
    .owl-item {
      .slide {
        .owl--text {
          width:100%;
        }
      }
    }
  }
  .search-container {
    width: 100%;
  }

  .searchbar {
    .col-search {
      width: 62%;
      .form-control {
        font-size: 20px;
      }
    }
    .col-sort {
      width: 25%;
    }
    .col-price {
      display: none;
    }
  }

  .img-container {
    .img-large {
      .card {
        .title {
          .price {
            font-size:30px;
          }
        }
      }
    }
  }

  .s-houses {
    .container {
      width: 100%;
      #form-order {
        top:-90px;
      }
    }
  }
  .s-houses {
    .houses {
      height:575px;
      .item,
      .owl-item,
      .owl-stage-outer {
        height: 575px;
      }
      .house {
        .image {

        }
      }
    }
  }

  #zoeker {
    #mijn-zoeker {
      &:before {
        background-repeat: no-repeat;
        background-size: 80%;
        left: -55px;
        top: -8px;
      }
    }
  }

  input[type=text],
  input[type=email],
  input[type=tel],
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .col-sidebar {
    .sidebar {
      display:none !important;
    }
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  a.reset-filter {
    position: absolute;
    right: 250px;
    top: 0;
    background: white;
    padding: 8px 10px 10px 10px;
    &:hover,
    &:focus {
      background:$orange;
      color:white;
    }
  }
}